import moment from "moment";

Array.prototype.remove = function (value) {
  let index = this.indexOf(value)
  if (index > -1) {
    this.splice(index, 1)
  }
  return index
}

//移除对象数组，匹配唯一key
Array.prototype.removeByKey = function (key, val) {
  let index = this.findIndex(value => value[key] === val)
  if (index > -1) {
    this.splice(index, 1)
  }
  return index
}

//对象数组转map
Array.prototype.toMap = function (key) {
  let map = new Map()
  this.forEach(v => map.set(v[key] * 1, v))
  return map
}

// 年月日时分秒格式
export const getTime = () => {
  let time = new Date();
  let res =
    time.getFullYear() +
    "-" +
    (time.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    time.getDate().toString().padStart(2, "0") +
    " " +
    time.getHours().toString().padStart(2, "0") +
    ":" +
    time.getMinutes().toString().padStart(2, "0") +
    ":" +
    time.getSeconds().toString().padStart(2, "0");
  return res
}

//自适应宽度
export const flexColumnWidth = (title, records, code) => {
  // console.log(title, records, code);
  let longestStr = "";
  records &&
    records.forEach((item) => {
      if (longestStr.length < (item[code] && item[code].length))
        longestStr = item[code];
      // console.log(item[code]);
    });
  // console.log(longestStr);
  title = title + "";
  let str = "";
  if (title.length > longestStr.length) str = title;
  else str = longestStr;

  // 以下分配的单位长度可根据实际需求进行调整
  let flexWidth = 0;
  for (let i = 0; i < str.length; i++) {
    let char = str[i];
    if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
      // 如果是英文字符，为字符分配8个单位宽度
      flexWidth += 8;
    } else if (char >= "\u4e00" && char <= "\u9fa5") {
      // 如果是中文字符，为字符分配15个单位宽度
      flexWidth += 18;
    } else {
      // 其他种类字符，为字符分配8个单位宽度
      flexWidth += 10;
    }
  }
  if (flexWidth < 120) {
    // 设置最小宽度
    flexWidth = 120;
  }
  if (flexWidth > 500) {
    // 设置最大宽度
    flexWidth = 500;
  }
  // console.log(flexWidth);
  return flexWidth + 28 + "px";
}

// 根据不同表单设置颜色
export const rowStyle = ({ row }) => {
  switch (row.Status) {
    case "已结束":
      return { color: "#000" };
    case "草稿":
      return { color: "#83af50" };
    case "进行中":
      return { color: "#83af50" };
    case "已取消":
      return { color: "#a0a0a0" };
    default:
      return { color: "#000" };
  }
}

// 设置单元格样式
export const cellStyle = ({ column, rowIndex }) => {
  if (column.type === "selection") {
    if (rowIndex % 2 !== 0) {
      return {
        color: "#000",
        position: "sticky",
        left: "0",
        backgroundColor: "#fAfAfA",
        "z-index": "10",
      };
    } else {
      return {
        color: "#000",
        position: "sticky",
        left: "0",
        backgroundColor: "#fff",
        "z-index": "10",
        // borderLeft: "1px solid rgb(245, 247, 250)",
      };
    }
  }
  if (column.type === "index") {
    if (rowIndex % 2 !== 0) {
      return {
        color: "#000",
        position: "sticky",
        left: "50px",
        backgroundColor: "#fAfAfA",
        "z-index": "10",
      };
    } else {
      return {
        color: "#000",
        position: "sticky",
        left: "50px",
        backgroundColor: "#fff",
        "z-index": "10",
        // borderRight: "1px solid rgb(245, 247, 250)",
      };
    }
  }
  if (column.property === "Name") {
    if (rowIndex % 2 !== 0) {
      return {
        color: "#409EFF",
        position: "sticky",
        left: "110px",
        backgroundColor: "#fAfAfA",
        "z-index": "10",
        // borderRight: "1px solid #e0e3e9",
        // boxShadow: " 2px 0 #e0e3e9"
      };
    } else {
      return {
        color: "#409EFF",
        position: "sticky",
        left: "110px",
        backgroundColor: "#fff",
        "z-index": "10",
        // borderRight: "2px solid #e0e3e9",
        // boxShadow: " 2px 0 #e0e3e9"
      };
    }
  } else {
    return {
      // borderRight: "1px solid #e0e3e9",
    };
  }
}

// 设置表头单元格样式
export const headerCellStyle = ({ column }) => {
  if (column.type === "selection") {
    return {
      position: "sticky",
      left: "0",
      backgroundColor: "#F5F7FA",
      "z-index": "10",
      // borderLeft: "1px solid #e0e3e9",
    };
  } else if (column.type === "index") {
    return {
      position: "sticky",
      left: "50px",
      backgroundColor: "#F5F7FA",
      "z-index": "10",
      // borderRight: "1px solid #e0e3e9",
    };
  } else if (column.property === "Name") {
    return {
      position: "sticky",
      left: "110px",
      backgroundColor: "#F5F7FA",
      "z-index": "10",
      // borderRight: "1px solid #e0e3e9",
    };
  } else {
    return {
      backgroundColor: "#F5F7FA",
      // borderRight: "1px solid #e0e3e9",
    };
  }
}

export const changeTime = (time, format) => {
  if (["当天", "昨天", "明天"].includes(time))
    return setDate(time, format);
  if (["本周", "上周", "下周"].includes(time))
    return setWeek(time, format);
  if (["本月", "上月", "下月"].includes(time))
    return setMonth(time, format);
  if (["本季度"].includes(time)) return setQuarter(time, format);
  if (["本年度", "上年度", "下年度"].includes(time))
    return setYear(time, format);
}
function setDate(time, format) {
  let date = new Date().getDate();
  switch (time) {
    case "当天":
      date;
      break;
    case "明天":
      date += 1;
      break;
    case "昨天":
      date -= 1;
      break;
  }
  let today = moment(new Date()).date(date).format("YYYY-MM-DD");
  let startTime =
    format === "yyyy-MM-dd HH:mm:ss" ? today + " 00:00:00" : today;
  let finishTime =
    format === "yyyy-MM-dd HH:mm:ss" ? today + " 23:59:59" : today;

  return [startTime, finishTime];
}
function setWeek(time, format) {
  let monday = null;
  let sunday = null;
  let weekOfDay = new Date().getDay(); // 今天星期几
  switch (time) {
    case "本周":
      monday = moment().weekday(1).format("YYYY-MM-DD"); //本周一
      sunday = moment().weekday(7).format("YYYY-MM-DD"); //本周日
      break;

    case "上周":
      monday = moment()
        .subtract(weekOfDay + 7 - 1, "days")
        .format("YYYY-MM-DD"); //上周一
      sunday = moment().subtract(weekOfDay, "days").format("YYYY-MM-DD"); //上周五
      break;
    case "下周":
      monday = moment()
        .add(7 - weekOfDay + 1, "d")
        .format("YYYY-MM-DD"); //下周一
      sunday = moment()
        .add(7 - weekOfDay + 7, "d")
        .format("YYYY-MM-DD"); //下周五
      break;
  }
  let startTime =
    format === "yyyy-MM-dd HH:mm:ss" ? monday + " 00:00:00" : monday;
  let finishTime =
    format === "yyyy-MM-dd HH:mm:ss" ? sunday + " 23:59:59" : sunday;
  return [startTime, finishTime];
}
function getDays(year, month) {
  const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
    days[1] = 29;
  }
  return days[month];
}
function setMonth(time, format) {
  let thisYear = new Date().getFullYear();
  let thisMonth = new Date().getMonth(); // 本月
  let startMonth = null;
  let endMonth = null;
  switch (time) {
    case "本月":
      startMonth = `${thisYear}-${thisMonth < 9 ? "0" + (thisMonth + 1) : thisMonth + 1
        }-01`;
      endMonth = `${thisYear}-${thisMonth < 9 ? "0" + (thisMonth + 1) : thisMonth + 1
        }-${this.getDays(thisYear, thisMonth)}`;
      break;

    case "上月":
      if (thisMonth === 0) {
        startMonth = `${thisYear - 1}-12-01`;
        endMonth = `${thisYear - 1}-12-31`;
      } else {
        startMonth = `${thisYear}-${thisMonth < 9 ? "0" + thisMonth : thisMonth + 1
          }-01`;
        endMonth = `${thisYear}-${thisMonth < 9 ? "0" + thisMonth : thisMonth + 1
          }-${this.getDays(thisYear, thisMonth - 1)}`;
      }
      break;
    case "下月":
      if (thisMonth === 11) {
        startMonth = `${thisYear + 1}-01-01`;
        endMonth = `${thisYear + 1}-01-31`;
      } else {
        startMonth = `${thisYear}-${thisMonth < 9 ? "0" + (thisMonth + 2) : thisMonth + 1
          }-01`;
        endMonth = `${thisYear}-${thisMonth < 9 ? "0" + (thisMonth + 2) : thisMonth + 1
          }-${this.getDays(thisYear, thisMonth + 1)}`;
      }
      break;
  }
  // console.log(startMonth, endMonth);
  let startTime =
    format === "yyyy-MM-dd HH:mm:ss"
      ? startMonth + " 00:00:00"
      : startMonth;
  let finishTime =
    format === "yyyy-MM-dd HH:mm:ss" ? endMonth + " 23:59:59" : endMonth;
  return [startTime, finishTime];
}

function setQuarter(time, format) {
  let thisYear = new Date().getFullYear();
  let thisMonth = new Date().getMonth(); // 本月
  let quarter = Math.ceil((thisMonth + 1) / 3);
  let startMonth = `${thisYear}-0${(quarter - 1) * 3 + 1}-01`;
  let endMonth = `${thisYear}-${quarter < 4 ? "0" + quarter * 3 : quarter * 3
    }-${this.getDays(thisYear, quarter * 3 - 1)}`;
  let startTime =
    format === "yyyy-MM-dd HH:mm:ss"
      ? startMonth + " 00:00:00"
      : startMonth;
  let finishTime =
    format === "yyyy-MM-dd HH:mm:ss" ? endMonth + " 23:59:59" : endMonth;
  return [startTime, finishTime];
}
function setYear(time, format) {
  let thisYear = new Date().getFullYear();
  switch (time) {
    case "本年度":
      thisYear;
      break;
    case "上年度":
      thisYear -= 1;
      break;
    case "下年度":
      thisYear += 1;
      break;
  }
  let startTime =
    format === "yyyy-MM-dd HH:mm:ss"
      ? `${thisYear}-01-01` + " 00:00:00"
      : `${thisYear}-01-01`;
  let finishTime =
    format === "yyyy-MM-dd HH:mm:ss"
      ? `${thisYear}-12-31` + " 23:59:59"
      : `${thisYear}-12-31`;
  return [startTime, finishTime];
}
