<template>
  <div></div>
</template>

<script>
import { getLoginByTempCode } from "@/api/setProcess.js";
import { getLogin } from "@/api/login";
import * as dd from "dingtalk-jsapi";
import { errorMsg } from "@/api/design";
export default {
  name: "Detail",
  data() {
    return {};
  },
  methods: {},
  created() {
    if (dd.env.platform !== "notInDingTalk") {
      dd.getAuthCode({
        corpId: "ding8ade6b5e21085cf135c2f4657eb6378f",
        success: async function (res) {
          await this.$store.dispatch("user/isDDLogin", res.code);
          errorMsg(`'钉钉代办详情页', ${JSON.stringify(res)}`);
          // console.log(token)
          let data = await this.$store.dispatch("formData/getDetailData", {
            formId: this.$route.query.code,
            id: this.$route.query.objId,
            type: "see",
          });
          // 重置页面栈
          this.$store.commit("formData/setPageNameList", data);
        },
        fail: function (err) {
          console.log(err, "err");
        },
      });
    } else {
      getLoginByTempCode(this.$route.query.tempCode).then((token) => {
        this.$store.commit("user/setToken", token.data);
        if (document.documentElement.clientWidth < 700) {
          // console.log(token.data);
          if (process.env.NODE_ENV == "development") {
            window.location.href = `http://192.168.2.53:8080/#/pages/list/listDetail/?token=${token.data}&code=${this.$route.query.code}&objId=${this.$route.query.objId}`; // 开发环境
          } else if (
            process.env.NODE_ENV == "production" &&
            window.location.href.includes("http://test")
          ) {
            window.location.href = `http://test.cloud.yibeirui.com/h5/index.html#/pages/list/listDetail/?token=${token.data}&code=${this.$route.query.code}&objId=${this.$route.query.objId}`; // 测试环境
          } else {
            window.location.href = `http://cloud.yibeirui.com/h5/index.html#/pages/list/listDetail/?token=${token.data}&code=${this.$route.query.code}&objId=${this.$route.query.objId}`; // 生产环境
          }
        }
        getLogin().then(async (login) => {
          this.$store.commit("user/setUserInfo", login.data);
          let data = await this.$store.dispatch("formData/getDetailData", {
            formId: this.$route.query.code,
            id: this.$route.query.objId,
            type: "see",
          });
          // 重置页面栈
          this.$store.commit("formData/setPageNameList", data);
        });
      });
    }
  },
};
</script>

<style>
</style>