<template>
  <div v-if="isVisibility">
    <el-drawer
      title=""
      :visible.sync="isVisibility"
      direction="rtl"
      :before-close="handleClose"
      size="100%"
      :modal="false"
      destroy-on-close
      v-loading.fullscreen.lock="formLoading"
      element-loading-text="拼命加载中"
    >
      <template #title>
        <div style="display: flex; align-items: center">
          <div
            style="color: #3d3d3d; margin-left: 10px"
            v-if="pageNameList.length === 1"
          >
            {{ viewPage.formName }}
            <span
              v-if="fieldData && fieldData.Status"
              style="
                font-size: 12px;
                margin-left: 10px;
                color: #fff;
                border-radius: 4px;
                padding: 2px 6px;
              "
              :style="{ background: statusColor[fieldData.Status] }"
              >{{ statusName[fieldData.Status] }}</span
            >
          </div>
          <el-breadcrumb style="margin-left: 10px" separator="/" v-else>
            <el-breadcrumb-item
              style="cursor: pointer"
              @click.native="handleBreadcrumb(item, index)"
              v-for="(item, index) in pageNameList"
              :key="index"
            >
              <span class="font-size:20px">
                {{ item.formName }}
              </span>
            </el-breadcrumb-item>
            <span
              v-if="fieldData && fieldData.Status"
              style="
                font-size: 12px;
                margin-left: 10px;
                color: #fff;
                border-radius: 4px;
                padding: 2px 6px;
              "
              :style="{ background: statusColor[fieldData.Status] }"
              >{{ statusName[fieldData.Status] }}</span
            >
          </el-breadcrumb>
          <div
            style="margin-left: auto"
            v-if="type !== 'add' && type !== 'copy'"
          >
            <el-button
              type="primary"
              size="small"
              style="margin-right: 20px"
              @click="handleCopy"
              >复制</el-button
            >
            <el-button
              type="primary"
              size="small"
              style="margin-right: 40px"
              @click="handlePrint"
              v-if="btnPermission.includes('PRINT')"
              >打印</el-button
            >
          </div>
        </div>
      </template>
      <div class="drawerPage" v-show="!formLoading">
        <div class="content">
          <div
            v-if="type !== 'add' && type !== 'copy' && relevanceList.length > 1"
            style="width: 98%"
          >
            <ul class="tabs">
              <li
                class="tabsItems"
                v-for="item in relevanceList"
                :key="item.id"
                :class="{ activeTabItems: activeTabItems.id === item.id }"
                @click="handleTab(item)"
              >
                {{ item.formName }}
                <span>
                  {{
                    item.count || item.count === 0
                      ? `(${item.count})`
                      : item.count
                  }}
                </span>
              </li>
            </ul>
          </div>
          <el-form
            :model="formData"
            label-position="top"
            v-if="
              activeTabItems.id === fieldData.formId ||
              type === 'add' ||
              type === 'copy'
            "
            ref="ruleForm"
          >
            <el-row>
              <el-col
                v-for="(item, index) in field"
                :key="item.code + index"
                :span="item.parentId ? 12 : 24"
                v-show="!item.showTable"
              >
                <el-form-item
                  v-if="
                    item.itemLimit &&
                    item.itemLimit.isShow &&
                    !item.isHide &&
                    item.formId
                  "
                  :label="item.title"
                  label-width="100px"
                  style="margin: 20px 20px"
                  :rules="[
                    {
                      required: item.itemLimit.isRequire,
                      message: '请输入',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                  :prop="item.code"
                >
                  <component
                    :is="item.name"
                    :item="item"
                    :formData="formData"
                    @queryList="queryList"
                    :field.sync="field"
                    :type="type"
                  >
                  </component>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div v-else style="padding: 30px 22px">
            <el-form
              v-show="isCollapse"
              v-if="relevanceDetails.search"
              :model="tableFormData"
              :inline="true"
              label-position="left"
              class="formData"
              :style="{ height: !isCollapse ? '0px' : '100%' }"
            >
              <el-form-item
                v-for="item in relevanceDetails.search"
                :key="item.code"
                :label="item.title"
                label-width="100px"
                style="margin-left: 20px"
              >
                <template #label>
                  <el-popover
                    v-if="item.title.length > 6"
                    placement="top-start"
                    :width="item.title.length * 12"
                    trigger="hover"
                    :content="item.title"
                  >
                    <div
                      slot="reference"
                      style="
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100px;
                      "
                    >
                      {{ item.title }}
                    </div>
                  </el-popover>
                  <div v-else>{{ item.title }}</div>
                </template>
                <component
                  :is="item.name"
                  :item="item"
                  :formData="tableFormData"
                  @queryList="tableQueryList"
                >
                </component>
              </el-form-item>
            </el-form>
            <div
              class="isCollapseSearch"
              v-if="relevanceDetails && relevanceDetails.search"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="!isCollapse ? '展开搜索' : '收起搜索'"
                placement="top"
              >
                <i
                  :class="
                    !isCollapse
                      ? 'el-icon-d-arrow-right'
                      : 'el-icon-d-arrow-left'
                  "
                  @click="isCollapse = !isCollapse"
                >
                </i>
              </el-tooltip>
            </div>
            <el-button
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="handleTableAdd"
              >新增数据</el-button
            >
            <el-table
              style="margin-top: 10px"
              v-loading="tableLoading"
              element-loading-text="拼命加载中"
              :data="relevanceDetails.data.records"
              border
              :row-style="rowStyle"
            >
              <el-table-column
                type="index"
                width="60"
                align="center"
                label="序号"
                fixed
              >
              </el-table-column>
              <el-table-column
                prop="Name"
                label="数据标题"
                align="center"
                width="250"
                fixed
                show-overflow-tooltip
              >
                <template #default="{ row }">
                  <div @click="handleName(row)">
                    <span href="#" class="caption" style="color: #409eff">
                      {{ row.Name || "\\" }}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="column.title"
                :prop="column.code"
                v-for="column in relevanceDetails.field"
                :key="column.code"
                show-overflow-tooltip
                align="center"
                :width="
                  ['Created', 'Updated', 'DateTime'].includes(column.code)
                    ? '180px'
                    : flexColumnWidth(
                        column.title,
                        relevanceDetails.data.records,
                        column.code
                      )
                "
              ></el-table-column>
            </el-table>
            <el-pagination
              v-if="total > 10"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="10"
              layout="total, sizes, prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>

        <!-- -----------------------------侧边栏---------------------------- -->
        <div
          class="process"
          :style="{ width: open ? '420px' : '80px' }"
          v-if="
            type !== 'add' &&
            type !== 'copy' &&
            activeTabItems.formName === '详情'
          "
        >
          <el-tabs
            v-model="activeName"
            @tab-click="handleClick"
            v-if="open"
            style="padding-left: 20px"
          >
            <el-tab-pane label="流程日志" name="flowLog">
              <flowLog
                :flowLogList="flowLogList"
                v-if="flowLogList && flowLogList.length > 0"
                @handleUrge="handleUrge"
              ></flowLog>
              <el-empty description="暂无数据" v-else></el-empty>
            </el-tab-pane>
            <el-tab-pane
              :label="`评论(${commentList && commentList.length})`"
              name="comment"
            >
              <comment :commentList.sync="commentList"></comment>
            </el-tab-pane>
            <el-tab-pane label="操作日志" name="operateLog">
              <operateLog
                v-if="operateLogList && operateLogList.length > 0"
                :operateLogList="operateLogList"
                style="padding-bottom: 60px"
              ></operateLog>
              <el-empty description="暂无数据" v-else></el-empty>
            </el-tab-pane>
          </el-tabs>
          <div v-else>
            <div
              v-for="item in sidebarIcon"
              :key="item.id"
              class="tickets"
              @click="handleOriginal(item.id)"
            >
              <div>
                <img
                  :src="item.icon"
                  alt=""
                  style="width: 48px; height: 48px"
                />
              </div>
              <span>{{ item.name }}</span>
            </div>
          </div>
          <div>
            <div
              :class="`arrow-${open ? 'right' : 'left'}`"
              :style="{ right: open ? '420px' : '80px' }"
              @click="handleOpen"
            >
              <div v-if="open" class="left"></div>
              <div v-else class="right"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="confirmBtn">
        <el-button
          type="success"
          v-if="isShow"
          @click="
            taskApproveDialog = true;
            $set(form, 'comment', '同意');
            status = 1;
          "
          size="small"
          >同意</el-button
        >
        <el-button
          type="primary"
          v-if="isShow"
          @click="
            taskApproveDialog = true;
            $set(form, 'comment', '拒绝');
            status = 2;
          "
          size="small"
          >拒绝</el-button
        >
        <el-button
          type="primary"
          v-if="isShow"
          @click="
            taskApproveDialog = true;
            $set(form, 'comment', '');
            status = 3;
          "
          size="small"
          >转交</el-button
        >
        <el-button
          type="primary"
          v-if="
            type === 'see' &&
            btnPermission.includes('SUBMIT') &&
            fieldData &&
            fieldData.Status === 'Effective' &&
            activeTabItems.id === fieldData.formId
          "
          size="small"
          @click="handleEdit"
          >编辑</el-button
        >
        <el-button
          type="primary"
          @click="handleDel"
          v-if="
            type === 'edit' &&
            btnPermission.includes('DELETE') &&
            activeTabItems.id === fieldData.formId
          "
          size="small"
          >删除</el-button
        >
        <el-button
          type="primary"
          @click="confirmInfo"
          v-if="
            type === 'add' ||
            (type !== 'see' &&
              btnPermission.includes('SUBMIT') &&
              activeTabItems.id === fieldData.formId)
          "
          size="small"
          >保存</el-button
        >
      </div>
    </el-drawer>
    <el-dialog
      title="审批"
      :visible.sync="taskApproveDialog"
      width="600px"
      @close="form = {}"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" label-width="90px">
        <el-form-item :label="`${status === 2 ? '*' : ''}输入意见：`">
          <el-input v-model="form.comment" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="*转交人员：" v-if="status == 3">
          <component
            :is="'UserPicker'"
            :formData="form"
            :item="{ code: 'deliverId' }"
          ></component>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="taskApproveDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleTaskApprove">确 定</el-button>
      </span>
    </el-dialog>
    <printPage
      :showPrintPage.sync="showPrintPage"
      :field="field"
      :formOptionInfo="fieldData"
      :pageNameList="pageNameList"
      :logList="logList"
    ></printPage>
  </div>
</template>

<script>
import { pageListAdd, pageListDel } from "@/api/drawerPage";
import { getPageViewList, getoperateLogDetail } from "@/api/process";
import { taskApprove, getProcessDetail, urgeFlow } from "@/api/setProcess";
import components from "@/views/home/CompExport";
import flowLog from "./flowLog";
import operateLog from "./operateLog";
import comment from "./comment";
import printPage from "./printPage";
import { flexColumnWidth, rowStyle, getTime } from "@/utils/CustomUtil.js";
import { getCommentList } from "@/api/design";
export default {
  name: "drawerPage",
  components: { ...components, flowLog, operateLog, printPage, comment },
  computed: {
    isVisibility: {
      get() {
        return this.$store.state.formData.isVisibility;
      },
      set(val) {
        this.$store.commit("formData/setIsVisibility", val);
      },
    },
    //
    field() {
      return this.$store.state.formData.field;
    },
    btnPermission() {
      return this.$store.state.formData.btnPermission;
    },
    type: {
      get() {
        return this.$store.state.formData.drawerPageType;
      },
      set(val) {
        this.$store.commit("formData/setDrawerPageType", val);
      },
    },
    isShow() {
      let user = this.$store.getters.userInfo;
      if (
        this.approve?.approveUsers?.includes(user.id) &&
        this.type !== "copy" &&
        this.activeTabItems.id === this.viewPage.formId
      ) {
        return true;
      } else return false;
    },
    viewPage() {
      return this.$store.getters.viewPage;
    },
    pageNameList() {
      return this.$store.state.formData.pageNameList;
    },
    field() {
      return this.$store.state.formData.field;
    },
    approve() {
      return this.$store.state.formData.approve;
    },
    fieldData() {
      return this.$store.state.formData.fieldData;
    },
    activeTabItems: {
      get() {
        return this.$store.state.formData.activeTabItems;
      },
      set(val) {
        this.$store.commit("formData/setActiveTabItems", val);
      },
    },
    relevanceList() {
      return this.$store.state.formData.relevanceList;
    },
  },
  mounted() {},
  watch: {
    formData: {
      handler(val) {
        this.field.forEach((item) => {
          if (item.hideValue) {
            let obj = JSON.parse(item.hideValue) || {};
            this.$set(item, "isHide", eval(obj.hiddenVal));
          }
        });
      },
      deep: true,
    },
    fieldData: {
      deep: true,
      handler() {
        this.open = false;
        this.setFormData();
      },
    },
  },
  data() {
    return {
      isCollapse: false,
      total: 0,
      pageSize: 10,
      pageNumber: 1,
      tableLoading: false,
      formLoading: false,
      relevanceDetails: {
        data: {},
        field: [],
        search: [],
      },
      form: {},
      searchParamsJson: {},
      flowLogList: [],
      operateLogList: [],
      commentList: [],
      logList: [],
      activeName: "flowLog",
      open: false,
      tableFormData: {},
      formData: {},
      status: null,
      taskApproveDialog: false,
      showPrintPage: false,
      statusName: {
        Effective: "已完成",
        Running: "进行中",
        Canceled: "已取消",
        Draft: "草稿",
      },
      statusColor: {
        Effective: "#2e98fe",
        Running: "#EDAF31",
        Canceled: "#3D3D3D",
        Draft: "#3D3D3D",
      },
      sidebarIcon: [
        {
          name: "流程日志",
          id: "flowLog",
          icon: "https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/容器 14262@2x.png",
        },
        {
          name: "评论",
          id: "comment",
          icon: "https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/20240229100942/comment.png",
        },
        {
          name: "操作日志",
          id: "operateLog",
          icon: "https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/容器 14262@2x (1).png",
        },
      ],
    };
  },
  methods: {
    // 详情接口
    async handleFormVal(data) {
      this.formLoading = true;
      await this.$store.dispatch("formData/getDetailData", data);
      this.formLoading = false;
    },

    // 设置打印
    async handlePrint() {
      this.logList = [];
      let res = await getProcessDetail(this.fieldData.ProcessId);
      res.data.reverse().forEach((item) => {
        if (item.taskList.length > 0) {
          item.taskList.reverse().forEach((el) => {
            if (el.nodeType !== "CC") {
              this.logList.push(...item.taskList);
            }
          });
        }
      });
      console.log(this.logList);
      this.showPrintPage = true;
    },
    // 设置表单复制
    handleCopy() {
      let obj = {
        formName: this.viewPage.formName + "-复制新增",
        formId: this.fieldData.formId,
        type: "copy",
      };
      this.$store.commit("formData/setPageNameList", {
        val: obj,
        type: "add",
      });
      this.handleFormVal(obj);
      this.setFormData();
    },
    // 详情关联表单数据新增
    async handleTableAdd() {
      let obj = {
        formName: this.activeTabItems.formName + "-新增",
        relevance: {
          id: this.fieldData.id,
          Name: this.fieldData.Name,
        },
        formId: this.activeTabItems.id,
        type: "add",
      };
      this.$store.commit("formData/setPageNameList", { val: obj, type: "add" });
      this.handleFormVal(obj);
    },
    // 设置初始化数据
    setFormData() {
      let userInfo = this.$store.getters.userInfo;
      this.field.sort((a, b) => {
        return a.position - b.position;
      });
      this.formData = {};
      this.field.forEach((item) => {
        if (item.name === "ImageUpload") item.value = [];
        if (item.name === "FileUpload") item.value = [];
        if (item.name === "TableList") item.value = [];
        if (this.type === "add" || this.type === "copy") {
          if (item.code === "CreatedBy") {
            this.$set(this.formData, item.code, [
              {
                id: userInfo.id,
                name: userInfo.name,
                type: "user",
                avatar: userInfo.avatar,
              },
            ]);
          } else if (item.code === "OwnerId") {
            this.$set(this.formData, item.code, [
              {
                id: userInfo.id,
                name: userInfo.name,
                type: "user",
                avatar: userInfo.avatar,
              },
            ]);
          } else if (item.code === "Created") {
            let res = getTime(new Date());
            this.$set(this.formData, item.code, res);
          } else if (item.name === "MultipleSelect" && this.type !== "copy") {
            this.$set(
              this.formData,
              item.code,
              item.value ? item.value.split(",") : item.value
            );
          } else if (item.code === "CreatedDept") {
            this.$set(this.formData, item.code, [
              {
                id: userInfo.deptId,
                name: userInfo.deptName,
                avatar: null,
                type: "dept",
              },
            ]);
          } else if (item.name === "Relevance" && this.viewPage.relevance) {
            // console.log(this.viewPage, this.activeTabItems);
            this.$set(this.formData, item.code, this.viewPage.relevance);
          } else {
            if (this.type === "add") {
              this.$set(this.formData, item.code, item.value);
            } else {
              if (item.code === "SerialNumber") {
                this.$set(this.formData, item.code, null);
              } else {
                this.$set(this.formData, item.code, this.fieldData[item.code]);
              }
            }
          }
        } else {
          this.$set(this.formData, item.code, this.fieldData[item.code]);
        }
      });
      // console.log(this.formData)
    },
    // 路由栈点击
    async handleBreadcrumb(item, index) {
      if (
        item.formId === this.$route.query.code &&
        item.formName === this.viewPage.formName
      )
        return;
      this.$store.commit("formData/setPageNameList", {
        type: "splice",
        val: index,
      });
      this.handleFormVal({
        ...this.viewPage,
        type: "see",
      });
    },
    // 流程按钮
    async handleTaskApprove() {
      let deliverId = this.form.deliverId ? this.form.deliverId[0]?.id : null;
      if (this.status == 2 && !this.form.comment)
        return this.$message.info("请输入拒绝原因");
      if (this.status == 3 && !deliverId)
        return this.$message.info("请选择转交人");
      await taskApprove({
        comment: this.form.comment,
        deliverId,
        instanceId: this.fieldData.ProcessId,
        status: this.status,
      });
      this.taskApproveDialog = false;
      this.handleClose("refresh");
    },
    // 删除
    handleDel() {
      this.$confirm("此操作将永久删除该表单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await pageListDel(this.$route.query.code, [this.$route.query.objId]);
          this.$message.success("删除成功");
          this.handleClose("refresh");
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },
    // 编辑
    handleEdit() {
      this.type = "edit";
    },
    // 保存
    confirmInfo() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.formLoading = true;
          let res;
          if (this.type === "add" || this.type === "copy") {
            res = await pageListAdd(
              {
                ...this.formData,
              },
              this.$route.query.code,
              this.type
            );
          } else {
            res = await pageListAdd(
              {
                ...this.formData,
                Id: this.$route.query.objId,
              },
              this.fieldData.formId,
              "update"
            );
          }
          if (res.code === 500) {
            this.formLoading = false;
            this.$message.info(res.message);
            return;
          }
          this.$message.success(this.type === "add" ? "新增成功" : "编辑成功");
          this.formLoading = false;
          this.handleClose("refresh");
        } else {
          return false;
        }
      });
    },
    // 关联表单搜索条件
    tableQueryList() {
      this.searchParamsJson = {
        ...this.searchParamsJson,
        ...this.tableFormData,
      };
      this.getTableList(this.activeTabItems.id, "stop");
    },
    // 关联表单点击
    async handleTab(item) {
      console.log(item, "item");
      if (item.id === this.activeTabItems.id) return;
      this.activeTabItems = item;
      if (item.formName === "详情") {
        this.handleFormVal({
          ...this.viewPage,
          type: "see",
        });
      } else {
        this.tableLoading = true;
        if (item.mappingItem) {
          this.searchParamsJson = {};
          this.$set(this.searchParamsJson, item.mappingItem, [
            { Id: this.fieldData.id },
          ]);
        }
        this.getTableList(this.activeTabItems.id);
      }
    },
    // 获取子表数据
    async getTableList(id, str) {
      // console.log(id);
      let res = await getPageViewList({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        formId: id,
        searchParamsJson: this.searchParamsJson,
      });
      this.$set(this.relevanceDetails, "data", res.data.data);
      this.$set(this.relevanceDetails, "field", res.data.field);
      if (!str) {
        this.$set(this.relevanceDetails, "search", res.data?.search);
        this.relevanceDetails.search = this.relevanceDetails.search.filter(
          (item) => item.code !== this.activeTabItems.mappingItem
        );
        this.relevanceDetails.search.forEach((item) => {
          this.$set(this.tableFormData, item.code, null);
        });
      }
      this.total = res.data.data.total;
      this.tableLoading = false;
    },
    // 子表点击数据标题
    async handleName(row) {
      console.log(row);
      let obj = {
        formName: this.activeTabItems.formName,
        id: row.id,
        formId: this.activeTabItems.id,
      };
      let index = this.pageNameList.findIndex(
        (item) => item.formId === obj.formId
      );
      obj.index = index;
      // 判断是否有路由栈 有则替换
      this.$store.commit("formData/setPageNameList", {
        val: obj,
        type: index !== -1 ? "replace" : "add",
      });
      this.handleFormVal({
        ...this.viewPage,
        type: "see",
      });
    },
    // 每行样式
    rowStyle,
    //自适应宽度
    flexColumnWidth,

    // 子表分页
    handleSizeChange(val) {
      this.tableLoading = true;
      this.pageSize = val;
      this.getTableList(this.activeTabItems.id, "stop");
    },
    // 子表分页
    handleCurrentChange(val) {
      this.tableLoading = true;
      this.pageNumber = val;
      this.getTableList(this.activeTabItems.id, "stop");
    },
    // 流程催办
    handleUrge(text) {
      urgeFlow({
        dataId: this.fieldData.id,
        formId: this.fieldData.formId,
        remark: text,
      });
    },
    // 侧边日志
    handleOpen() {
      this.open = !this.open;
      if (this.open && this.activeName === "flowLog") {
        this.getFlowLogList();
      }
      if (this.open && this.activeName === "operateLog") {
        this.getOperateLog();
      }
      if (this.open && this.activeName === "comment") {
        this.getCommentListFn();
      }
    },
    // 侧边日志
    handleOriginal(str) {
      this.activeName = str;
      this.handleOpen();
    },
    // 侧边日志
    handleClick(tab) {
      if (tab.name === "flowLog") {
        this.getFlowLogList();
      } else if (tab.name === "operateLog") {
        this.getOperateLog();
      } else {
        this.getCommentListFn();
      }
    },
    // 获取流程日志
    async getFlowLogList() {
      let res = await getProcessDetail(this.fieldData.ProcessId);
      this.flowLogList = res.data.reverse();
      this.flowLogList.forEach((item) => {
        if (item.taskList.length > 1) {
          item.taskList = item.taskList.reverse();
        }
      });
    },
    // 获取操作日志
    async getOperateLog() {
      let res = await getoperateLogDetail(
        this.fieldData.id,
        this.fieldData.formId
      );
      this.operateLogList = res.data;
    },
    // 获取评论日志
    getCommentListFn() {
      getCommentList({
        dataId: this.fieldData.id,
        formId: this.fieldData.formId,
        pageNumber: 1,
        pageSize: 100,
      }).then((res) => {
        this.commentList = res.data.records || [];
      });
    },
    // 关闭弹窗
    async handleClose(str) {
      // 详情页面 从钉钉跳转到这里 关闭直接关闭网页
      if (this.$route.path === "/detail") {
        window.close();
        return;
      }
      //如果缓存页面有多个关闭最后一个页面 不需要关闭弹窗
      if (this.pageNameList.length > 1) {
        this.$store.commit("formData/setPageNameList", {
          val: this.pageNameList.length - 2,
          type: "splice",
        });
        this.handleFormVal({
          ...this.viewPage,
          type: "see",
        });
      } else {
        if (str === "refresh") this.$store.commit("formData/setRefresh", true);
        else {
          this.$router.push(
            {
              query: {
                code: this.$store.state.formData.tableData.id,
              },
            },
            () => {}
          );
        }
        this.isVisibility = false;
      }
    },
    queryList() {
      console.log(this.formData);
    },
  },
};
</script>

<style scoped lang="less" src='./index.less'>
</style>