import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store'
require('@/utils/CustomUtil')

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "@/assets/theme.less";
import "@/assets/global.css";
import "@/assets/iconfont/iconfont.css"

import MyGlobalComponent from '@/views/drawerPage/index'
import Ellipsis from '@/components/common/Ellipsis'
import WDialog from '@/components/common/WDialog'
import '@/permission'
import { version } from '../globalConfig.json'
import {
  errorMsg
} from "@/api/design";

// 全局注册组件
Vue.component('MyGlobalComponent', MyGlobalComponent);
Vue.use(ElementUI);
Vue.use(Ellipsis);
Vue.use(WDialog);

// 关闭el-autocomplete组件选择输入建议时触发change事件
Vue.directive("get-selected-value", {
  inserted: function () {
    const vnode = arguments[2].child;
    // 这里$off是为了移除高版本代码中新增的@change监听，否则直接加代码会有两个监听事件，触发两次change
    vnode?.$refs?.input &&
      vnode.$refs.input.$off("change").$on("change", (val) => {
        if (vnode.$refs?.suggestions?.showPopper) {
          const unwatch = vnode.$watch(
            () => vnode.$refs.suggestions.showPopper,
            function () {
              unwatch();
              vnode.$emit("change", val);
            }
          );
          vnode.$once("select", (item) => {
            unwatch();
          });
        } else {
          vnode.$emit("change", val);
        }
      });
  },
}
)

Vue.config.productionTip = false

Vue.prototype.$isNotEmpty = (obj) => (obj !== undefined && obj !== null && obj !== '' && obj !== 'null')

Vue.prototype.$deepCopy = (obj) => JSON.parse(JSON.stringify(obj))

Vue.prototype.$version = () => version


// 全局错误处理
Vue.config.errorHandler = function (err, vm, info) {
  console.error('Vue 全局错误捕获:', err, info);
  errorMsg(`${'PC全局错误捕获:'},${store.state.user.userInfo.name},${err} , ${info}`)
};

// 阻止火狐浏览器拖拽时创建新窗口
document.body.ondrop = function (event) {
  event.preventDefault();
  event.stopPropagation();
};
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
