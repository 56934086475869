<template>
  <div
    v-loading.fullscreen.lock="formLoading"
    element-loading-text="拼命加载中"
  >
    <div v-if="type === 'see'" class="disabledCss">
      <div v-if="formData[item.code] && formData[item.code].length > 0">
        <span
          v-for="op in formData[item.code]"
          :key="op.id"
          @click="handleDetails(item, op)"
        >
          {{ op.Name }}
        </span>
      </div>
      <div v-else class="select" @click="handleDetails(item)">
        <span v-if="formData[item.code] && formData[item.code].Name">{{
          formData[item.code].Name
        }}</span>
      </div>
    </div>

    <el-select
      v-else
      v-model="formData[item.code]"
      size="medium"
      clearable
      :multiple="item.name === 'MultipleRelevance'"
      placeholder="请选择"
      @change="queryList"
      style="width: 100%"
      :disabled="disabled"
      value-key="id"
      v-el-select-load="handleLoad"
      filterable
      remote
      reserve-keyword
      :remote-method="remoteMethod"
      @focus="handleFocus"
    >
      <el-option value="--" label="--(空值)" v-if="!item.itemLimit">
      </el-option>
      <el-option
        v-for="op in options"
        :key="op.id"
        :value="op"
        :label="op.Name"
      ></el-option>
      <el-option value="加载中" label="加载中" v-if="loadingShip" disabled>
      </el-option>
      <el-option
        value="到底啦"
        label="到底啦"
        v-if="isBottom && options.length > 6"
        disabled
        style="text-align: center"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { getPageViewList, getFormFillList } from "@/api/process";
import { getPageOneInfo } from "@/api/drawerPage";
import Minxins from "../Minxins";
export default {
  name: "Relevance",
  mixins: [Minxins],
  directives: {
    "el-select-load": {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          //临界值的判断滑动到底部就触发
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value(el.childNodes[1].firstElementChild.value);
          }
        });
      },
    },
  },
  data() {
    return {
      options: [],
      loadingShip: false,
      pageNumber: 1,
      total: 0,
      rulesList: [],
      formLoading: false,
    };
  },
  watch: {
    type(val) {
      if (val === "edit") {
        this.handleOption({ Name: this.formData[this.item.code]?.Name });
      }
    },
  },
  computed: {
    isBottom() {
      if (this.total === Math.ceil(this.options.length / 10) && this.total)
        return true;
      else return false;
    },
    viewPage() {
      return this.$store.getters.viewPage;
    },
    fieldData() {
      return this.$store.state.formData.fieldData;
    },
  },
  created() {
    if (this.type === "add" && this.viewPage.relevance) {
      this.options = [{ ...this.viewPage.relevance }];
    }
    if (this.type === "copy") {
      this.options = [this.fieldData[this.item.code]];
    }
  },
  methods: {
    handleFocus() {
      if (this.type !== "see") {
        this.handleOption({});
      }
      getFormFillList({
        formId: this.$route.query.code,
        itemId: this.item.id,
      }).then((res) => {
        this.rulesList = res.data.fillList;
      });
    },
    async handleDetails(item, op) {
      // console.log(item, op, this.formData[this.item.code]);
      this.formLoading = true;
      if (
        !this.formData[this.item.code] ||
        this.formData[this.item.code].length === 0 ||
        this.type !== "see"
      )
        return;
      let obj = {};
      let res =
        op && this.formData[this.item.code].find((el) => el.id === op.id);
      obj = {
        ...item,
        formName: item.relevanceName,
        formId: item.options,
        id: op ? res.id : this.formData[this.item.code].id,
        type: "see",
      };

      let index = this.$store.state.formData.pageNameList.findIndex(
        (item) => item.formId === obj.formId
      );
      obj.index = index;
      this.$store.commit("formData/setPageNameList", {
        val: obj,
        type: index !== -1 ? "replace" : "add",
      });
      await this.$store.dispatch("formData/getDetailData", obj);
      this.formLoading = false;
    },
    remoteMethod(val) {
      this.handleOption({ Name: val }, "search");
    },
    handleLoad(e) {
      if (this.isBottom) return;
      this.handleOption({ Name: e }, "load");
    },
    queryList(val) {
      this.$emit("queryList");
      if (this.item.name === "Relevance") {
        getPageOneInfo(this.item.options, val.id).then((res) => {
          this.rulesList.forEach((item) => {
            this.$set(
              this.formData,
              item.thisItemCode,
              res.data.data[item.thatItemCode]
            );
          });
        });
      }
    },
    async handleOption(searchParamsJson, str) {
      if (this.loadingShip) return;
      this.loadingShip = true;
      if (str === "load") this.pageNumber += 1;
      else this.pageNumber = 1;
      let res = await getPageViewList({
        pageNumber: this.pageNumber,
        pageSize: 10,
        formId: this.item.options,
        searchParamsJson,
      });
      this.loadingShip = false;
      if (str === "load") {
        res.data.data.records.forEach((item) => {
          if (this.options.find((elm) => elm.id === item.id)) {
            return;
          } else {
            this.options.push(item);
          }
        });
      } else {
        this.options = res.data.data.records;
      }
      // console.log(this.options);
      this.total = Math.ceil(res.data.data.total / 10);
    },
  },
};
</script>

<style  scoped lang="less">
.disabledCss {
  padding: 0 !important;
  span {
    margin: 4px 4px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #e9e9eb;
    padding: 5px;
    border-radius: 5px;
    color: #107fff;
    white-space: normal;
  }
}
</style>
