<template>
  <div
    class="contentPage"
    v-if="tableList"
    v-loading.fullscreen.lock="pageLoading"
    element-loading-text="拼命加载中"
  >
    <div class="contentTable">
      <el-table
        :data="tableList.data.records"
        @sort-change="handleSort"
        @selection-change="handleSelectionChange"
        :row-style="rowStyle"
        :cell-style="cellStyle"
        stripe
        border
        v-loading="loading"
        :header-cell-style="headerCellStyle"
        style="height: 100%"
      >
        <el-table-column type="selection" width="50" align="center">
        </el-table-column>
        <el-table-column
          type="index"
          width="60"
          align="center"
          label="序号"
          :index="seIindex"
        >
        </el-table-column>
        <el-table-column
          prop="Name"
          label="数据标题"
          align="center"
          :width="flexColumnWidth('数据标题', tableList.data.records, 'Name')"
        >
          <template #default="{ row }">
            <div @click="drawerPage(row)">
              <span href="#" style="cursor: pointer">
                <ellipsis hoverTip :row="1" :content="row.Name"> </ellipsis>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="sortable(item)"
          v-for="item in tableList.field"
          :key="item.itemId"
          :prop="item.code"
          :label="item.title"
          align="center"
          :width="
            ['Created', 'Updated', 'DateTime'].includes(item.code)
              ? '180px'
              : tableList.field.length < 5
              ? 'auto'
              : flexColumnWidth(item.title, tableList.data.records, item.code)
          "
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <div
              @click="toDetail(row, item)"
              style="
                display: block;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              :class="{ relevance: isToDetail.includes(item.name) }"
            >
              {{ row[item.code] }}
            </div>
          </template>
          <el-table-column
            v-for="k in item.items"
            :key="k.code"
            :prop="k.code"
            :label="k.title"
            align="center"
            :min-width="
              ['Created', 'Updated', 'DateTime'].includes(k.code)
                ? '180px'
                : flexColumnWidth(
                    k.title,
                    tableList.data.records[item.code],
                    k.code
                  )
            "
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <div v-for="(num, i) in row[k.formId].slice(0, 4)" :key="i">
                <div>
                  <el-image
                    v-if="
                      row[k.formId][i][k.code] &&
                      ['png', 'jpg'].includes(
                        row[k.formId][i][k.code]
                          .toString()
                          .substring(
                            row[k.formId][i][k.code].toString().length - 3
                          )
                      )
                    "
                    :src="row[k.formId][i][k.code]"
                    alt=""
                    style="width: 80px; height: 80px"
                  />
                  <div v-else>
                    {{ row[k.formId][i][k.code] }}
                  </div>
                </div>
              </div>
              <div v-if="row[k.formId].length > 4">······</div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="人员信息" :visible.sync="userInfoDialog" width="600px">
      <div style="display: flex" v-if="userInfoDialog">
        <div style="display: flex; margin: auto">
          <el-image
            style="width: 150px; height: 150px; border-radius: 20px"
            :src="userInfo.avatar"
            v-if="userInfo.avatar"
          ></el-image>
          <span
            v-else
            style="
              text-align: center;
              line-height: 150px;
              background: #1890ff;
              color: white;
              margin-left: 10px;
              display: block;
              width: 150px;
              height: 150px;
              border-radius: 20px;
              font-size: 40px;
            "
            >{{
              userInfo.name && userInfo.name.split("-").length > 1
                ? userInfo.name
                    .split("-")[0]
                    .slice(
                      userInfo.name.split("-")[0].length - 2,
                      userInfo.name.split("-")[0].length
                    )
                : userInfo.name.slice(
                    userInfo.name.length - 2,
                    userInfo.name.length
                  )
            }}</span
          >
          <div style="margin-left: 100px">
            <ul
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
              "
            >
              <li>姓名：{{ userInfo.name }}</li>
              <li>员工工号：{{ userInfo.jobNum }}</li>
              <li>职位：{{ userInfo.position }}</li>
              <li>部门：{{ userInfo.deptName }}</li>
              <li>联系方式：{{ userInfo.phone }}</li>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/org";
import {
  flexColumnWidth,
  rowStyle,
  cellStyle,
  headerCellStyle,
} from "@/utils/CustomUtil.js";
export default {
  props: {
    tableList: {
      require: true,
      default: () => {},
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  inject: {
    getViewList: "getViewList",
  },
  data() {
    return {
      loading: false,
      pageLoading: false,
      isToDetail: ["Relevance", "CreatedBy"],
      userInfoDialog: false,
      userInfo: {},
    };
  },

  methods: {
    seIindex(i) {
      return i + 1 + (this.pageNumber - 1) * this.pageSize;
    },
    // 看详情
    async toDetail(row, item) {
      console.log(row, item);
      if (!this.isToDetail.includes(item.name)) return;
      let code = row[`${item.code}Id`];
      if (item.name === "Relevance") {
        this.pageLoading = true;
        let data = await this.$store.dispatch("formData/getDetailData", {
          formId: item.options,
          id: code,
          type: "see",
        });
        // 重置页面栈
        this.$store.commit("formData/setPageNameList", data);
        this.pageLoading = false;
      } else {
        let res = await getUserInfo(code);
        this.userInfo = res.data;
        this.userInfoDialog = true;
      }
    },
    sortable(item) {
      let canSort = ["Updated", "DateTime", "Created", "AmountInput"];
      if (canSort.includes(item.name)) {
        return "custom";
      } else {
        return false;
      }
    },
    // 设置单元格样式
    cellStyle,
    // 根据不同表单设置颜色
    rowStyle,
    //自适应宽度
    flexColumnWidth,
    // 设置表头单元格样式
    headerCellStyle,
    handleSelectionChange(val) {
      this.$store.commit("formData/setMultipleSelection", val);
    },
    handleSort(column) {
      console.log(column,'column')
      this.getViewList(
        column.prop,
        column.order === "ascending" ? "asc" : "desc",
        false
      );
    },
    async drawerPage(row) {
      this.pageLoading = true;
      let result = await this.$store.dispatch("formData/getDetailData", {
        formId: this.$route.query.code,
        id: row.id,
        type: "see",
      });
      this.$store.commit("formData/setPageNameList", result);
      this.pageLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.contentPage {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
}

.relevance {
  cursor: pointer;
  color: #409eff;
}

.contentTable {
  /deep/ .el-table {
    overflow: unset;
    .el-table__body-wrapper {
      z-index: 2;
    }
    &__fixed-right::before,
    .el-table__fixed::before {
      background-color: transparent;
    }
  }
  /deep/ .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background-color: transparent;
  }

  /* 表格不出现横向滚动条 */
  /deep/ .el-table--scrollable-x .el-table__body-wrapper {
    overflow: unset !important;
  }
  /deep/ .el-table__header-wrapper {
    overflow: unset !important;
    position: sticky;
    top: 0px;
    z-index: 12;
  }
  /deep/ .el-table__header {
    border-top: 1px solid #ebeef5;
  }
  /deep/ .el-table--border,
  .el-table--group {
    border: unset !important;
  }

  /deep/ .el-table .has-gutter .el-table__cell {
    padding: 0 2px;
    line-height: 46px;
  }

  /deep/ .el-table .el-table__cell {
    line-height: 34px;
  }
  /deep/
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: #f5f7fa !important;
  }
}

/deep/ .el-dialog__header {
  text-align: center;
}
</style>