<template>
  <div class="backlogPage">
    <el-container>
      <el-header
        style="
          background: #fff;

          height: 50px;
        "
      >
        <div style="display: flex; align-items: center" class="w1200">
          <el-button
            circle
            size="small"
            icon="el-icon-arrow-left"
            @click="goBack"
          >
          </el-button>

          <div class="title">
            <span
              @click="isActive = 'todo'"
              :class="{ isActive: isActive === 'todo' }"
              >我的待办</span
            >
            <span
              @click="isActive = 'launch'"
              :class="{ isActive: isActive === 'launch' }"
              >我发起的</span
            >
          </div>
        </div>
      </el-header>

      <el-main style="height: calc(100vh - 50px)">
        <div class="condition w1200">
          <el-select
            v-model="allStatus"
            placeholder="状态"
            size="small"
            style="width: 100px"
            @change="handleAllStatus"
          >
            <el-option label="进行中" value="进行中"> </el-option>
            <el-option label="我参与的" value="我参与的"> </el-option>
            <el-option label="已完成" value="已完成"> </el-option>
          </el-select>

          <el-select
            v-model="sort"
            placeholder="排序"
            size="small"
            style="width: 160px; margin-left: 20px"
            @change="handleAllStatus"
          >
            <el-option label="按最近创建排序" value="按最近创建排序">
            </el-option>
            <el-option label="按最近截止排序" value="按最近截止排序">
            </el-option>
            <el-option label="按最近更新排序" value="按最近更新排序">
            </el-option>
          </el-select>

          <el-select
            v-model="myJoin"
            placeholder="所属应用"
            size="small"
            style="width: 200px; margin: 0 20px"
            clearable
            @change="handleAllStatus"
          >
            <el-option
              v-for="(item, index) in todoNum"
              :key="index"
              :label="`${item.formName} (${item.num})`"
              :value="item.formId"
            >
            </el-option>
          </el-select>

          <div style="float: right" class="search">
            <el-input
              style="width: 260px"
              size="small"
              v-model="condition"
              placeholder="请输入关键词、内容"
              suffix-icon="el-icon-search"
            ></el-input>
            <el-button
              size="small"
              style="margin-left: 20px"
              @click="getCondition"
              type="primary"
              >搜索
            </el-button>
          </div>
          <div
            style="
              display: flex;
              font-size: 16px;
              padding: 0 60px;
              margin-top: 30px;
              color: #222222;
            "
          >
            <div style="width: 25%">标题</div>
            <div style="width: 35%">内容</div>
            <div style="width: 25%">
              {{ isActive === "todo" ? "接收时间" : "提交时间" }}
            </div>
            <div style="width: 15%">操作</div>
          </div>
        </div>
        <div class="content w1200">
          <el-empty
            description="当前没有任何内容"
            v-if="list && list.length < 1"
          ></el-empty>
          <div class="item" v-for="(item, index) in list" :key="index" v-else>
            <div style="display: flex; align-items: center; width: 25%">
              <img
                v-if="item.createAvatar"
                :src="item.createAvatar"
                alt=""
                width="50px"
                height="50px"
                style="border-radius: 50%"
              />
              <div
                v-else
                style="
                  width: 50px;
                  height: 50px;
                  text-align: center;
                  line-height: 50px;
                  background: #1890ff;
                  color: white;
                  border-radius: 50%;
                "
              >
                {{
                  item.createName && item.createName.length > 6
                    ? item.createName
                        .split("-")[0]
                        .slice(
                          item.createName.split("-")[0].length - 2,
                          item.createName.split("-")[0].length
                        )
                    : item.createName.slice(
                        item.createName.length - 2,
                        item.createName.length
                      )
                }}
              </div>
              <span style="margin-left: 10px">{{ item.formName }}</span>
            </div>
            <div style="width: 35%; white-space: pre-line">
              {{ item.content }}
            </div>
            <div style="width: 25%">{{ setTime(item.created) }}</div>
            <div
              style="
                color: #488fe1;
                cursor: pointer;

                width: 15%;
              "
              @click="handleJoin(item)"
            >
              {{ isActive === "todo" ? "开始审批" : "查看详情" }}
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { getTodoNum, getTodoData } from "@/api/setProcess";
import { getPageOneInfo } from "@/api/drawerPage";
export default {
  name: "Backlog",
  data() {
    return {
      condition: "",
      allStatus: "",
      sort: "",
      myJoin: "",
      isActive: "todo",
      todoNum: [],
      list: [],
      total: 0,
      formOption: [],
      formOptionInfo: {},
      approve: {},
    };
  },
  provide() {
    return {
      getViewList: this.getAllTodeData,
    };
  },
  created() {
    this.todoNumFn();
    this.getAllTodeData();
  },
  methods: {
    handleJoin(item) {                                
      this.$router.push(
        {
          query: {
            code: item.formId,
            objId: item.dataId,
          },
        },
        () => {}
      );
      getPageOneInfo(item.formId, item.dataId).then((res) => {
        // console.log(res);
        let code = JSON.parse(localStorage.getItem("recover"));
        const setObj = {
          btns: res.data.btns,
          name: res.data.data.formName,
          options: item.formId,
          objId: item.dataId,
          code: code.code,
          type: "stop",
        };
        this.$store.commit("formData/setTableData", setObj);
        this.formOption = res.data.field;
        this.formOptionInfo = res.data.data;
        this.approve = res.data.approve;
        this.$nextTick(() => {
          this.$refs.drawerPage.type = "see";
          this.$refs.drawerPage.row = {
            Id: item.dataId,
            options: item.formId,
            status: res.data.data.Status,
            ProcessId: res.data.data.ProcessId,
          };
          this.$refs.drawerPage.drawer = true;
        });
      });
    },
    getAllTodeData() {
      getTodoData({}).then((res) => {
        console.log(res);
        this.list = res.list;
      });
    },
    todoNumFn() {
      getTodoNum({
        sort: "",
      }).then((res) => {
        if (res.code === 200 && res.data && res.data.length > 0) {
          this.todoNum = res.data;
        }
      });
    },
    setTime(time) {
      function format(item) {
        return item < 10 ? "0" + item : item;
      }
      let str = "";
      time.forEach((item, index) => {
        if (index < 2) {
          str += format(item) + "-";
        } else if (index === 2) {
          str += format(item) + " ";
        } else if (index < 5) {
          str += format(item) + ":";
        } else {
          str += format(item);
        }
      });
      return str;
    },
    goBack() {
      this.$router.push("/home");
    },
    getCondition() {
      console.log(this.condition);
    },
    handleAllStatus(e) {
      console.log(e);
    },
  },
};
</script>
<style lang="less" scoped>
.backlogPage {
  height: 100vh;
  background: #f5f7fa;
  min-width: 1000px;
  .title {
    margin: auto;
    font-size: 16px;
    width: 160px;
    display: flex;
    justify-content: space-between;
    line-height: 48px;
    color: #303133;
    cursor: pointer;
  }
  .isActive {
    border-bottom: 3px solid #409eff;
    color: #409eff;
  }
  .condition {
    // overflow: hidden;
    position: sticky;
    top: 0px;
    z-index: 2;
    background-color: #f5f7fa;
    padding: 28px 0;
    .search {
      ::v-deep .el-input__inner {
        border: 1px solid #409eff;
      }
    }
  }
  .content {
    .item {
      padding: 20px 60px;
      margin-bottom: 14px;
      background-color: #fff;
      display: flex;
      align-items: center;
      font-size: 16px;
      //   &>*{
      //     padding-left: 10p;
      //   }
      &:hover {
        box-shadow: 0 2px 14px 0 rgba(95, 134, 218, 0.2);
      }
    }
  }
}
.el-main {
  padding: 0 !important;
}
.w1200 {
  min-width: 1000px;
  max-width: 1200px;
  overflow: hidden;
  margin: 0 auto;
}
</style>
