<template>
  <div>
    <div
      class="disabledCss"
      v-if="(type === 'see' || item.code === 'CreatedBy') && !item.type"
    >
      <span v-for="op in formData[item.code]" :key="op.id">
        {{ op.name }}
      </span>
    </div>
    <div
      style="border: 1px solid #dcdfe6; cursor: pointer; width: 99%;    border-radius: 4px;"
      v-else
      @click="handleOpenPicker"
    >
      <div style="text-align: center">
        <el-button
          icon="el-icon-user"
          size="mini"
          v-show="!formData[item.code] || formData[item.code].length < 1"
          round
          :disabled="disabled"
          style="border: 1px dashed #dcdfe6"
          @click="$refs.orgPicker.show()"
          >选择人员</el-button
        >
        <org-picker
          type="user"
          :multiple="
            item.name === 'MultipleUserPicker' || (item.type && item.type === 2)
          "
          ref="orgPicker"
          :selected="formData[item.code] || []"
          @ok="selected"
          :disabled="disabled"
        />
        <!-- <span class="placeholder"> 请选择人员 </span> -->
        <div style="display: flex; flex-wrap: wrap">
          <el-tag
            size="mini"
            style="margin: 5px"
            closable
            v-for="(dept, i) in formData[item.code]"
            @close="delDept(i)"
            :key="i"
            >{{ dept.name }}</el-tag
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrgPicker from "@/components/common/OrgPicker";
import Minxins from "../Minxins";
export default {
  name: "UserPicker",
  components: { OrgPicker },
  mixins: [Minxins],
  data() {
    return {
      showOrgSelect: false,
    };
  },
  methods: {
    handleOpenPicker() {
      if (this.disabled) return;
      this.$refs.orgPicker.show();
    },
    selected(values) {
      this.showOrgSelect = false;
      this.$set(this.formData, this.item.code, values);
      this.$emit("queryList");
    },
    delDept(i) {
      this.formData[this.item.code].splice(i, 1);
    },
  },
};
</script>

<style scoped lang="less">
.disabledCss {
  padding: 0 !important;
  span {
    margin: 4px 4px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #e9e9eb;
    padding: 5px;
    color: #304265;
    border-radius: 5px;
    white-space: normal;
  }
}
</style>
