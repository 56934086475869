<template>
  <div>
    <el-autocomplete
      v-get-selected-value
      v-if="item.type && item.type === 2"
      size="medium"
      v-model="formData[item.code]"
      :fetch-suggestions="remoteMethod"
      placeholder="请输入内容"
      :trigger-on-focus="false"
      @select="queryList"
      @change="queryList"
      style="width: 100%"
      clearable
    ></el-autocomplete>
    <div v-else>
      <div v-if="disabled" class="disabledCss">
        <div
          v-for="(text, index) in formData[item.code] &&
          formData[item.code].split('\n')"
          :key="index"
        >
          <span v-if="text.includes('http')">
            <span v-for="(http, i) in text && text.split('http')" :key="i">
              <a
                :href="`http${http}`"
                v-if="i === 1"
                style="
                  color: #1890ff;
                  word-wrap: break-word;
                  text-decoration: none;
                "
                target="_blank"
              >
                http{{ http }}
              </a>
              <span v-else>{{ http }}</span>
            </span>
          </span>
          <span v-else> {{ text }}</span>
        </div>
      </div>
      <el-input
        v-else
        clearable
        show-word-limit
        :type="item.name === 'TextareaInput' ? 'textarea' : 'text'"
        v-model="formData[item.code]"
        :autosize="{ minRows: 2 }"
        @change="queryList"
        :maxlength="item.name === 'TextareaInput' ? 2000 : 255"
        placeholder="请输入内容"
        style="width: 100%"
      ></el-input>
    </div>
  </div>
</template>
<script>
import Minxins from "../Minxins";
import { getCandidateList } from "@/api/process";
export default {
  mixins: [Minxins],
  name: "TextInput",
  data() {
    return {};
  },
  methods: {
    async remoteMethod(val, cb) {
      let res = await getCandidateList(this.item.formId, this.item.code, val);
      let arr =
        res.data &&
        res.data.map((item) => {
          return { value: item };
        });
      cb(arr);
    },
  },
};
</script>
<style lang="less" scoped>
</style>

