<template>
  <div>
    <el-form label-width="90px" v-if="form.name !== 'SpanLayout'">
      <el-form-item label="控件编码" v-if="form.code">
        <el-input size="small" readonly v-model="form.code" />
      </el-form-item>
      <el-form-item label="控件名称">
        <el-input size="small" clearable v-model="form.title" />
      </el-form-item>
      <el-form-item label="组件名称">
        <el-input size="small" disabled v-model="form.name" />
      </el-form-item>
      <el-form-item label="隐藏条件">
        <el-input size="small" readonly :value="form.hideValue">
          <i
            slot="suffix"
            class="el-input__icon el-icon-plus"
            @click="handleValueHidden"
          ></i>
        </el-input>
      </el-form-item>
      <component
        :is="form.name"
        :formNumList.sync="form.formNumList"
        :option.sync="form.options"
        :code.sync="form.name"
        :id="form.id"
        :format.sync="form.format"
        :defaultVal.sync="form.value"
        v-if="
          ![
            'TextInput',
            'CreatedBy',
            'TextareaInput',
            'ImageUpload',
            'FileUpload',
            'DeptPicker',
            'MultipleDeptPicker',
            'UserPicker',
            'MultipleUserPicker',
            'TableList',
            'OwnerId',
            'CreatedDept',
            'Signature',
            'City',
          ].includes(form.name)
        "
      />
    </el-form>
    <el-empty v-else description="当前组件不支持配置"></el-empty>
    <div>
      <SetHide
        :isHidden.sync="isHidden"
        rulesTitle="当满足以下条件时此控件隐藏"
        type="isHidden"
        :dom.sync="dom"
      ></SetHide>
    </div>
  </div>
</template>

<script>
import MultipleSelect from "./config/SelectInputConfig.vue";
import AmountInput from "./config/AmountInputConfig.vue";
import Relevance from "./config/RelevanceConfig.vue";
import MultipleRelevance from "./config/RelevanceConfig.vue";
import SelectInput from "./config/SelectInputConfig.vue";
import DateTime from "./config/DateTimeConfig.vue";
import Description from "./config/DescriptionConfig.vue";
import Created from "./config/DateTimeConfig.vue";
import Updated from "./config/DateTimeConfig.vue";
import SetHide from "@/components/common/SetHide.vue";
import City from "./config/CityConfig.vue";
import SerialNumber from "./config/SerialNumberConfig.vue";
export default {
  name: "FormComponentConfig",
  components: {
    AmountInput,
    MultipleRelevance,
    SerialNumber,
    Relevance,
    SelectInput,
    MultipleSelect,
    DateTime,
    Description,
    Created,
    Updated,
    SetHide,
    City,
  },

  computed: {
    form() {
      return this.$store.state.selectFormItem;
    },
    dom: {
      get() {
        return (this.form.hideValue && JSON.parse(this.form.hideValue)) || {};
      },
      set(val) {
        this.form.hideValue = JSON.stringify(val);
      },
    },
  },
  data() {
    return {
      isHidden: false,
    };
  },
  methods: {
    handleValueHidden() {
      this.isHidden = true;
    },
  },
};
</script>

<style scoped lang="less">
.el-input__icon {
  cursor: pointer;
}
</style>
