import { getPageOneInfo, getRelevanceList, getDrawerPageOption } from "@/api/drawerPage";
import router from "@/router";
// 获取当前路由
const state = {
    tableData: {},
    pageNameList: [],
    multipleSelection: [],
    moduleList: [],
    btnPermission: [],
    externalBtnPermission: [],
    refresh: false,

    // 表单内数据详情
    drawerPageType: '',
    field: [],
    fieldData: {},
    approve: {},
    isVisibility: false,
    relevanceList: [],
    activeTabItems: {},
}
const mutations = {
    setPageNameList(state, val) {
        // console.log(val)
        if (val.type === 'add') {
            state.pageNameList.push(val.val)
        } else if (val.type === 'splice') {
            state.pageNameList.splice(val.val + 1)
            console.log(state.pageNameList)
        } else if (val.type === 'replace') {
            state.pageNameList.splice(val.val.index, 999, val.val)
        } else {
            state.pageNameList = [val]
        }
    },
    // 外面的表单
    setTableData(state, val) {
        state.tableData = val
        state.pageNameList = [val]
    },
    setMultipleSelection(state, val) {
        state.multipleSelection = val
    },
    setModuleList(state, val) {
        state.moduleList = val
    },
    setExternalBtnPermission(state, val) {
        state.externalBtnPermission = val
    },
    setBtnPermission(state, val) {
        state.btnPermission = val
    },
    setDrawerPageType(state, val) {
        state.drawerPageType = val
    },
    setIsVisibility(state, val) {
        state.isVisibility = val
    },
    setField(state, val) {
        state.field = val
    },
    setFieldData(state, val) {
        state.fieldData = val
    },
    setApprove(state, val) {
        state.approve = val
    },
    setRelevanceList(state, val) {
        state.relevanceList = val
    },
    setActiveTabItems(state, val) {
        state.activeTabItems = val
    },
    setRefresh(state, val) {
        state.refresh = val
    }

}

const actions = {
    async getDetailData(context, data) {
        // console.log(data)
        let res = {}
        if (data.type === 'add' || data.type === 'copy') {
            res = await getDrawerPageOption(data.formId, 'add')
            context.commit('setField', res.data)
        } else {
            res = await getPageOneInfo(data.formId, data.id)
            res.data.data.formId = res.data.formId
            context.commit('setField', res.data.field)
        }
        if (data.type === 'copy') {
            context.commit('setFieldData', context.state.fieldData || {})
        } else {
            context.commit('setFieldData', res.data.data || {})
        }
        context.commit('setApprove', res.data.approve || {})
        context.commit('setDrawerPageType', data.type)
        context.commit('setBtnPermission', res.data.btns || [])
        router.push(
            {
                query: {
                    code: data.formId,
                    objId: data.id,
                },
            },
            () => { }
        );
        context.commit('setIsVisibility', true)
        if (data.type !== "add" && data.type !== 'copy') {
            getRelevanceList(
                data.formId,
                data.id
            ).then((res) => {
                context.commit('setRelevanceList', res.data)
                context.commit('setActiveTabItems', res.data[0])
            });
        }
        return res.data.data
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}