<template>
  <el-container
    v-loading="loading"
    element-loading-text="保存中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-header style="background: white">
      <layout-header @publish="validateDesign"></layout-header>
    </el-header>
    <div class="layout-body">
      <router-view></router-view>
    </div>
  </el-container>
</template>

<script>
import LayoutHeader from "./LayoutHeader";
import { getFormDetail, createForm } from "@/api/design";
import ComponentsConfigExport from "@/views/common/form/ComponentsConfigExport.js";
export default {
  name: "FormProcessDesign",
  components: {
    LayoutHeader,
  },
  data() {
    return {
      ComponentsConfigExport,
      loading: false,
      dataNameList: [],
    };
  },
  computed: {
    setup() {
      return this.$store.state.design;
    },
  },
  created() {
    let formId = this.$route.query.code;
    //判断传参，决定是新建还是加载原始数据
    this.loadInitFrom();
    if (this.$isNotEmpty(formId)) {
      this.loadFormInfo(formId);
    }
    let group = this.$route.query.groupId;
    this.setup.groupId = this.$isNotEmpty(group) ? parseInt(group) : null;
  },
  methods: {
    loadFormInfo(formId) {
      let filterList = ["FileUpload", "ImageUpload", "TableList", "SpanLayout"];
      getFormDetail(formId)
        .then((rsp) => {
          // 设置数据标题
          let form = rsp.data;
          let formItems = [];
          console.log(form.formItems);
          form.formItems.forEach((item) => {
            if (!filterList.includes(item.name)) {
              formItems.push(item);
            } else if (item.name === "SpanLayout") {
              formItems.push(...item.items);
            }
          });
          this.$store.commit("setDataNameList", formItems);
          form.formItems = JSON.parse(JSON.stringify(form.formItems));
          this.$store.commit("loadForm", form);
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    loadInitFrom() {
      this.$store.commit("loadForm", {
        formId: null,
        formName: "未命名表单",
        logo: "https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/qingjiashenqing.png",
        pcLogo:
          "https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/2024/04/11/20240411161819/default.svg",
        groupId: undefined,
        formItems: [],
        remark: "备注说明",
      });
    },
    async validateDesign() {
      let id = this.$route.query.code;
      let isEmpty = true;
      let flag = true;
      this.loading = true;
      let template = {
        formName: this.setup.formName,
        logo: this.setup.logo,
        formItems: this.setup.formItems,
        remark: this.setup.remark,
        dataName: this.setup.dataName,
        id,
        pcLogo:this.setup.pcLogo,
        groupId: this.setup.groupId || this.$route.query.groupId,
      };

      // 判断容器组件和关联表单组件是否ok
      template.formItems.forEach((item) => {
        if (item.name === "SpanLayout" || item.name === "TableList") {
          if (item.items.length < 1) isEmpty = false;
          else {
            item.items.forEach((comps) => {
              if (
                comps.name === "Relevance" ||
                comps.name === "MultipleRelevance"
              ) {
                if (!comps.options) flag = false;
              }
            });
          }
        }
        if (item.name === "Relevance" || item.name === "MultipleRelevance") {
          if (!item.options) flag = false;
        }
      });
      if (!isEmpty) {
        this.loading = false;
        return this.$message.info("容器中最少添加一个组件");
      }
      if (!flag) {
        this.loading = false;
        return this.$message.info("请选择关联表单");
      }
      let res = await createForm(template, id ? "put" : "post");
      // this.$message.success("保存成功");
      this.$notify.success({
        title: "保存成功",
        type: "success",
      });
      if (res.code === 200 && res.message === "Success") {
        if (res.data) {
          this.loadFormInfo(res.data);
          this.$router.push({
            query: {
              code: res.data,
            },
          });
        } else {
          this.loadFormInfo(this.$route.query.code);
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.layout-body {
  min-width: 980px;
}

/deep/ .el-step {
  .is-success {
    color: #2a99ff;
    border-color: #2a99ff;
  }
}

.err-info {
  max-height: 180px;
  overflow-y: auto;
  & > div {
    padding: 5px;
    margin: 2px 0;
    width: 220px;
    text-align: left;
    border-radius: 3px;
    background: rgb(242 242 242);
  }
  i {
    margin: 0 5px;
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}
</style>
